// Used for Staging and Production
const CSP_SETTINGS = {
    'script-src': [
        "'self'",
        "'unsafe-eval'",
        "'unsafe-inline'",
        '*.afterpay.com',
        '*.clearpay.co.uk',
        '*.clearpay.com',
        '*.googleapis.com',
        'public.fbot.me',
        'static.fbot.me',
        'campaign.fbot.me',
        'lcx-embed.bambuser.com',
        'www.googletagmanager.com',
        '*.onetrust.com',
        'cdn.branch.io',
        'sc-static.net',
        'snap.licdn.com',
        'connect.facebook.net',
        'munchkin.marketo.net',
        'www.googleadservices.com',
        'cdn.dashhudson.com',
        'djnf6e5yyirys.cloudfront.net', // friendbuy sdk host
        'cdn.builder.io',
        't.contentsquare.net',
        'www.google-analytics.com',
        'googleads.g.doubleclick.net',
        'app.link',
        'v5tufwer.micpn.com',
        'pi.pardot.com',
        'tag.clearbitscripts.com/v1/pk_ba428737ee82fd942f13030da0c2629b/tags.js',
        'tag.rmp.rakuten.com',
        'metadata-static-files.sfo2.cdn.digitaloceanspaces.com/pixel/lp.js',
        'x.clearbitjs.com/v2/pk_ba428737ee82fd942f13030da0c2629b/tracking.min.js',
        'x.clearbitjs.com/v2/pk_ba428737ee82fd942f13030da0c2629b/destinations.min.js',
        'analytics.tiktok.com/i18n/pixel/events.js',
        'analytics.tiktok.com/i18n/pixel/config.js',
        'analytics.tiktok.com/i18n/pixel/identify.js',
        'bat.bing.com',
        'afterpay-business-site.vercel.app',
        'business.dijitally.com',
        '*.ads-twitter.com',
        'js.adsrvr.org',
        'cdn.bizible.com',
        'analytics.twitter.com',
        't.co',
        'bat.bing.com',
        'widget.trustpilot.com',
    ],
    'img-src': [
        "'self'",
        'data:',
        '*.afterpay.com',
        '*.clearpay.co.uk',
        'maps.gstatic.com',
        '*.googleapis.com',
        '*.ggpht',
        'cdn.builder.io',
        'static.fbot.me',
        'www.google.co.nz',
        'www.google.co.uk',
        'www.google.com',
        'www.google.com.au',
        'www.googletagmanager.com',
        'px.ads.linkedin.com',
        'www.facebook.com',
        'p.adsymptotic.com',
        'cdn.branch.io',
        'www.google-analytics.com',
        'public.fbot.me',
        'connect.facebook.net',
        '*.onetrust.com',
        'cdn.dashhudson.com',
        'likeshop.me',
        'track.linksynergy.com',
        'consent.linksynergy.com',
        'www.kmart.com.au',
        'www.bigw.com.au/',
        'www.pacsun.com',
        'afterpay-business-site.vercel.app',
        'business.dijitally.com',
        'cdn.bizible.com',
        '*.google.ie',
        'bat.bing.com',
        't.co',
        'cdn.bizibly.com',
        'cdn.builder.com',
    ],
    'object-src': ["'none'"],
    'base-uri': ["'none'"],
};

const CSP = Object.entries(CSP_SETTINGS)
    .reduce((previousValue, [key, cspValues]) => {
        const cspString = cspValues.join(' ');
        return `${previousValue} ${key} ${cspString};`;
    }, '')
    .trim();

module.exports = CSP;
