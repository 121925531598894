import builder, { Builder, BuilderComponent } from '@builder.io/react';
import { InferGetStaticPropsType } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { registerComponents } from '../builder';
import Breadcrumb, { BreadcrumbItem } from '../components/breadcrumb/breadcrumb';
import DefaultLayout from '../components/layouts/default-layout';
import MetaBlock from '../components/meta-block/MetaBlock';
import NewsletterSignUpSection from '../components/newsletter-signup-section/newsletter-signup-section';
import { DefaultLayoutContext } from '../contexts/DefaultLayoutContext';
import { getPageFromPageID } from '../helpers/BuilderHelper';

import { ALL_BUSINESS_PREFIXES, EXCLUDE_PAGES, LIVE_LOCALES, Locale, localifyLink, stripLocaleFromURL, useLocale } from '../helpers/LangHelper';

import { getCommonPageDetails } from '../shared/commonPageDetails';
import * as style from '../styles/generic.module.scss';

const BusinessResourceLayout = dynamic(() => import('../components/layouts/business-resouce-layout'));

registerComponents();

export async function getStaticProps(context: any) {
    let pageSlug = stripLocaleFromURL(context.params.slug.join('/') ?? '');
    const lang: Locale = context.params.slug[0];

    if (ALL_BUSINESS_PREFIXES.includes(pageSlug.replace(/\//g, ''))) {
        pageSlug = '/business/resources';
    }

    const page = await builder
        .get('page', {
            userAttributes: {
                urlPath: `${localifyLink(pageSlug, Locale.Australia)}`,
                locale: lang,
            },
        })
        .toPromise();

    const { footerItems, categories, canonicalUrl } = await getCommonPageDetails(pageSlug, lang, page);

    return {
        props: { page: page ?? null, categories, footerItems, canonicalUrl },
        revalidate: 3600,
    };
}

export async function getStaticPaths() {
    // TODO: loop and get more pages
    const pages = await builder.getAll('page', {
        options: {
            noTargeting: true,
        },
        limit: 100,
    });

    const allPaths: string[] = [];

    pages.forEach((page) => {
        if (!EXCLUDE_PAGES.includes(page.data?.url)) {
            LIVE_LOCALES.forEach((locale) => {
                allPaths.push(localifyLink(`/${locale}${page?.data?.url}`, locale));
            });
        }
    });

    return {
        paths: allPaths,
        fallback: false,
    };
}

const DefaultPage = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
    const { page } = props;

    const router = useRouter();
    const { locale } = useLocale();
    const [currentPage, setCurrentPage] = useState(page);

    useEffect(() => {
        let cancelled = false;

        if (!page) {
            if ((Builder.isPreviewing || Builder.isEditing) && router.query['builder.overrides.page']) {
                getPageFromPageID(router.query['builder.overrides.page'] as string).then((page) => {
                    if (!cancelled) {
                        setCurrentPage(page);
                    }
                });
            }
        }

        return () => {
            cancelled = false;
        };
    }, [page]);

    let breadcrumbs: BreadcrumbItem[] = [
        {
            name: 'Business',
            link: '/business',
        },
        {
            name: currentPage?.name,
            link: '',
        },
    ];

    if (currentPage?.data?.breadcrumbs) {
        breadcrumbs = currentPage.data.breadcrumbs.map((data: any) => ({ name: data.name, link: data.link ?? data.url }));
    }
    const breadcrumbsType: 'light' | 'dark' = currentPage?.data?.darkTheme ? 'light' : 'dark';
    useEffect(() => {
        const body = document.body;
        if (!body) {
            return;
        }
        if (currentPage?.data?.darkTheme) {
            body.classList.add('dark');
        } else {
            body.classList.remove('dark');
        }
    }, [currentPage?.data?.darkTheme]);

    useEffect(() => {
        const fetch = async () => {
            const urlPath = localifyLink(router.asPath, locale).replace(`/${locale}`, '');
            const oldUrlPath = currentPage.query.find((q: any) => q.property === 'urlPath');
            console.log({ urlPath, oldUrlPath });
            if (oldUrlPath.value === urlPath) return;

            const page = await builder
                .get('page', {
                    userAttributes: {
                        urlPath,
                        locale,
                    },
                })
                .toPromise();
            if (page) setCurrentPage(page);
        };
        fetch();
    }, [router]);

    const layout: 'default' | 'marketing resource' = currentPage?.data?.layout;

    return (
        <DefaultLayout breadcrumbs={breadcrumbs} footerItems={props.footerItems} categories={props.categories}>
            <MetaBlock title={currentPage?.data?.title} description={currentPage?.data?.description} />
            <div className={(style as any)['page-layout']}>
                {layout === 'marketing resource' ? (
                    <BusinessResourceLayout>
                        <BuilderComponent model="page" content={currentPage} />
                    </BusinessResourceLayout>
                ) : (
                    <>
                        {!currentPage?.data?.hideDefaultBreadcrumbs && (
                            <DefaultLayoutContext.Consumer>{({ type }) => <Breadcrumb breadcrumbs={breadcrumbs} type={breadcrumbsType || 'dark'} />}</DefaultLayoutContext.Consumer>
                        )}
                        <BuilderComponent model="page" content={page} />
                    </>
                )}
            </div>
            {layout === 'marketing resource' ? null : <NewsletterSignUpSection />}
        </DefaultLayout>
    );
};

export default DefaultPage;
