import Head from 'next/head';
import * as React from 'react';
import { useEffect, useState } from 'react';

const CSP = require('../../config/next.csp.config');

interface MetaBlockProps {
    title: string;
    description: string;

    leadImage?: string;
}

export default function MetaBlock(props: MetaBlockProps) {
    const { title, description, leadImage } = props;

    const [isAfterpay, setIsAfterpay] = useState(false);

    useEffect(() => {
        setIsAfterpay(window.location.host.startsWith('afterpay.com'));
    }, []);

    return (
        <Head>
            <meta charSet="utf-8" />
            <meta httpEquiv="Content-Security-Policy" content={CSP} />
            <meta name="google-site-verification" content="ZJMu9MsvjHJwPn0kcq31cioCrPxAw0HHWqWOqXpq1-Y" />

            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />

            {!isAfterpay && <meta name="robots" content="noindex" />}

            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />

            {leadImage && (
                <>
                    <meta property="og:image" content={leadImage} />
                    <meta property="twitter:image" content={leadImage} />
                </>
            )}
        </Head>
    );
}
